import { template as template_91ab1de6c0194ad78e2f5e72a05cb1bb } from "@ember/template-compiler";
const FKControlMenuContainer = template_91ab1de6c0194ad78e2f5e72a05cb1bb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
