import { template as template_e0638f694931443a8c0884d5c1a98f00 } from "@ember/template-compiler";
const FKText = template_e0638f694931443a8c0884d5c1a98f00(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
