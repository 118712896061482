import { template as template_85a6b37187da4da5a49f4695d53ca9a1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_85a6b37187da4da5a49f4695d53ca9a1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
